/*
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 14:53:28
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-07 11:47:03
 */
import request from '@/utils/request'

export function search (params) {
  return request({
    url: 'book/search',
    params
  })
}

export function getChapter (params) {
  return request({
    url: 'book/bookChapter',
    params
  })
}

export function getChapterDetail (params) {
  return request({
    url: 'book/bookDetails',
    params
  })
}

export function getBookClass (params) {
  return request({
    url: 'book/class',
    params
  })
}

export function getNotice (params) {
  return request({
    url: '/book/notice',
    params
  })
}