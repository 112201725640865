/*
 * @Descripttion: 纵有千古，横有八荒，前途似海，来日方长。
 * @version: 1.0
 * @Author: 番茄
 * @Date: 2021-12-31 13:37:48
 * @LastEditors: 番茄
 * @LastEditTime: 2022-01-04 09:47:11
 */
import axios from 'axios'

const service = axios.create({
  baseURL: 'https://api.92lzx.cn/api/',
  timeout: 10000
})

service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code !== 200) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
export default service